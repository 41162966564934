import React, { useRef, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/src/theme/appModern';
import { ResetCSS } from 'common/src/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from '../containers/AppModern/Navbar';
import Banner from '../containers/AppModern/Banner';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from '../containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';
import * as typeformEmbed from '@typeform/embed'

import SEO from '../components/seo';

export default function() {
  const typeformRef = useRef(null);

  useEffect(() => {
    typeformEmbed.makeWidget(typeformRef.current, 'https://form.typeform.com/to/j0Ifm8ew', {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
    });
  }, [typeformRef]);

  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="WSB - Forma de Registro" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>           
            <div ref={typeformRef} style={{ height: '100vh', width: '100%'}}></div>            
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
}
